import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
} from "@headlessui/react";
//import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../assets/images/UWC logo.png";
import { useAuth } from "../auth";
import { useAppStore } from "../store/appStore";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { validateToken } from "../_api/_request";

export default function Header() {
  const { logout, authLogin } = useAuth();
  const navigate = useNavigate();
  const { login, user, userLogout, isAuthenticated } = useAppStore();
  const userType = Cookies.get("authUserRole");
  const cookie_token = Cookies.get("authToken");

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      if (userType) {
        if (cookie_token && userType?.includes("partner")) {
          try {
            const resp = await validateToken(cookie_token);
            if (resp.ResponseCode === 200) {
              authLogin(resp.data);
              login(resp.data.userdetails);
              if (userType?.includes("partner") && resp.data.userdetails.UserId) {
                navigate("/");
              } else {
                navigate("/unauthorised");
              }
            } else {
              console.log(resp?.Response);
            }
          } catch (error) {
            console.error("Error in validateToken:", error);
          }
        } else if (userType !== "partner") {
          navigate("/unauthorised");
        }
      } else {
        navigate("/authenticate");
      }
    };

    checkAuthAndFetchData();
  }, [authLogin, login, navigate, userType, cookie_token]);

  const handleLogout = () => {
    logout();
    userLogout();
    Cookies.remove("authUserRole");
    Cookies.remove("authToken", {
      domain: ".unitedwecare.com",
      path: "/",
      // secure: true,
      HttpsOnly: true,
    });
    navigate("/authenticate");
  };

  const handleLogin = () => {
    window.location.href = `https://accounts.unitedwecare.com/?redirect=${window.location.href}`;
  };

  return (
    <>
      <Popover
        as="header"
        className="bg-white shadow-sm data-[open]:fixed data-[open]:inset-0 data-[open]:z-40 data-[open]:overflow-y-auto lg:static lg:overflow-y-visible data-[open]:lg:static data-[open]:lg:overflow-y-visible w-full border-b"
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-3">
          <div className="relative flex justify-between">
            <div className="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
              <div className="flex flex-shrink-0 items-center">
                <a href="/">
                  <img
                    alt="united we care logo"
                    src={logo}
                    className="h-8 w-auto"
                  />
                </a>
              </div>
            </div>
            <div className="flex items-center md:absolute md:inset-y-0 md:right-0 lg:hidden"></div>
            <div className="flex items-center justify-end xl:col-span-4">
              {/* Profile dropdown */}
              {isAuthenticated ? (
                <Menu as="div" className="relative ml-5 flex-shrink-0">
                  <div>
                    <MenuButton className="relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        alt=""
                        src={user?.ProfileImage}
                        className="h-10 w-10 rounded-full object-cover  ring-2 ring-offset-2 ring-violet-400"
                      />
                    </MenuButton>
                  </div>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <button
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 w-full"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              ) : (
                <button
                  onClick={handleLogin}
                  className="bg-primary-500 disabled:bg-primary-400 text-white inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 shadow hover:bg-primary-600 h-9 px-4 py-3.5"
                >
                  Login
                </button>
              )}
            </div>
          </div>
        </div>
        {/*         
        <PopoverPanel as="nav" aria-label="Global" className="lg:hidden">
          <div className="border-t border-gray-200 pb-3 pt-4">
            <div className="mx-auto flex max-w-3xl items-center px-4 sm:px-6">
              <div className="flex-shrink-0">
                <img
                  alt=""
                  src={user?.ProfileImage}
                  className="h-10 w-10 rounded-full"
                />
              </div>
              <div className="ml-3">
                <div className="text-base font-medium text-gray-800">
                  {user?.Name}
                </div>
                <div className="text-sm font-medium text-gray-500">
                  {user?.Email}
                </div>
              </div>
            </div>
            <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-4">
              {isAuthenticated ? (
                <Menu as="div" className="relative ml-5 flex-shrink-0">
                  <div>
                    <MenuButton className="relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        alt=""
                        src={user?.ProfileImage}
                        className="h-10 w-10 rounded-full object-cover  ring-2 ring-offset-2 ring-violet-400"
                      />
                    </MenuButton>
                  </div>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <MenuItem>
                      <button
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 w-full"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              ) : (
                <button
                  onClick={handleLogin}
                  className="bg-primary-500 disabled:bg-primary-400 text-white inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 shadow hover:bg-primary-600 h-9 px-4 py-3.5"
                >
                  Login
                </button>
              )}
            </div>
          </div>
        </PopoverPanel> */}
      </Popover>
    </>
  );
}
