import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import AuthenticationPage from "./pages/AuthenticationPage";
// import Iframe from "./pages/Iframe";
import NotAuthorized from "./pages/UnAuthorise";
import Layout from "./pages/Layout";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/authenticate" element={<AuthenticationPage />} />
          {/* <Route path="/unauthorised" element={<Iframe />} /> */}
          <Route path="/unauthorised" element={<NotAuthorized />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
