import axios, { AxiosResponse } from "axios";
import { jwtDecode } from "jwt-decode";

interface Config {
  headers: {
    "Content-Type": string;
    Authorization: string;
    "X-Email-Id": string | undefined;
    "X-API-Key": string | undefined;
    DeviceType: string;
    DeviceId: string;
    Lat: string;
    Long: string;
  };
  data: {};
}

interface User {
  UserId: string;
  Name: string;
  // Add other properties as needed
}

interface ResponseData {
  ResponseCode: number;
  data: string | User;
  // Add other properties if they exist in the response
}

const getConfig = (): Config => {
  const profileItem = window.localStorage.getItem("profile");
  const profile: User | null = profileItem ? JSON.parse(profileItem) : null;
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("Authorization") || "",
      "X-Email-Id": profile ? profile.Name : undefined, // Change Emailid to Name
      "X-API-Key": process.env.NEXT_PUBLIC_XAPIKEY,
      DeviceType: "web",
      DeviceId: "123",
      Lat: "1",
      Long: "1",
    },
    data: {},
  };
};

export const UserDetailsService = (): Promise<ResponseData> => {
  return axios
    .get<ResponseData>(
      `${process.env.REACT_APP_BASE_URL}api/Auth/GetUserbyId`,
      getConfig()
    )
    .then((resp: AxiosResponse<ResponseData>) => {
      if (resp.data.data && typeof resp.data.data === "string") {
        resp.data.data = jwtDecode<User>(resp.data.data);
      }
      return resp.data;
    });
};

export const validateToken = async (token: string) => {
  const resp = await axios.get(
    `${process.env.REACT_APP_BASE_URL}api/sso/validate_token?token=${token}`,
    getConfig()
  );
  const resp_1 = resp.data;
  if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
  return resp_1;
};

export const fetchLookerUrlApi = async (userId: number | string) => {
  const resp = await axios.get(
    `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/uwc_institute/get-looker-url?user_id=${userId}`,
    getConfig()
  );
  const resp_1 = resp.data;
  // if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
  return resp_1;
};
