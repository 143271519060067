import { useCallback } from "react";
import { UserDetailsService } from "../_api/_request";

interface Profile {
  UserId: string;
  Name: string;
  MobileNo?: string;
}

interface AuthData {
  token_type: string;
  access_token: string;
  userdetails: Profile;
}

interface Auth {
  currentUser: Profile | null;
  isLogedIn: () => Promise<boolean>;
  userType: {
    EXPERT: number;
    ENDUSER: number;
  };
  setCurrentUser: (profile: Profile) => void;
  login: (data: AuthData) => void;
  logout: () => void;
  updateMobileNo: (mobileNumber: string) => void;
  authLogin: (data: { usertoken: string; userdetails: Profile }) => void;
}

export const useAuth = (): Auth => {
  const setCurrentUser = useCallback((profile: Profile) => {
    localStorage.setItem("profile", JSON.stringify(profile));
  }, []);

  const authLogin = useCallback(
    (data: { usertoken: string; userdetails: Profile }) => {
      if (typeof window !== "undefined") {
        localStorage.setItem("Authorization", data.usertoken);
        setCurrentUser(data.userdetails);
        localStorage.setItem("profile", JSON.stringify(data.userdetails));
      }
    },
    [setCurrentUser]
  );

  return {
    currentUser: localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile") as string)
      : null,

    isLogedIn: async () => {
      const Authorization = localStorage.getItem("Authorization");
      if (Authorization) {
        const UserDetails = await UserDetailsService();
        return UserDetails?.ResponseCode === 200 ?? false;
      }
      return false;
    },

    userType: {
      EXPERT: 3,
      ENDUSER: 4,
    },

    setCurrentUser,

    login: (data: AuthData) => {
      localStorage.setItem(
        "Authorization",
        `${data.token_type} ${data.access_token}`
      );
      const now = new Date();
      const oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
      const firstName = data.userdetails.Name.split(" ")[0];
      document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
    },

    logout: () => {
      localStorage.removeItem("Authorization");
      localStorage.removeItem("profile");
      const now = new Date();
      const oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
      document.cookie = `userType=null;expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
      document.cookie = `displayName=null;expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
    },

    updateMobileNo: (mobileNumber: string) => {
      const profileJSON = localStorage.getItem("profile");
      if (profileJSON) {
        const profile: Profile = JSON.parse(profileJSON);
        profile.MobileNo = mobileNumber;
        const updatedProfileJSON = JSON.stringify(profile);
        localStorage.setItem("profile", updatedProfileJSON);
      }
    },

    authLogin,
  };
};
