import { create } from "zustand"

interface AppStore {
    isAuthenticated: boolean;
    user: any;
    // userType: string;
    login: (user: any) => void;
    userLogout: () => void;
}

export const useAppStore = create<AppStore>((set) => ({
    isAuthenticated: false,
    user: null,
    login: (user: any) => set({ isAuthenticated: true, user: user }),
    userLogout: () => set({ isAuthenticated: false, user: null }),

}))