import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../auth";
import { fetchLookerUrlApi } from "../_api/_request";
import { decryptDataAlt } from "../lib/utils";

const Home: React.FC = () => {
  const { currentUser } = useAuth();
  const [lookerUrl, setLookerUrl] = useState<string | undefined>(undefined);
  const [encryptLookerUrl, setEncryptLookerUrl] = useState<string | undefined>(
    undefined
  );
  const [width, setWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  useEffect(() => {
    const decryptUrl = async () => {
      if (encryptLookerUrl) {
        try {
          const decryptedUrl = await decryptDataAlt(encryptLookerUrl);
          console.log("decryptedUrl", decryptedUrl);
          setLookerUrl(decryptedUrl);
        } catch (error) {
          console.error("Error decrypting URL:", error);
        }
      }
    };

    decryptUrl();
  }, [encryptLookerUrl]);

  const fetchLookerUrl = useCallback(async () => {
    if (currentUser?.UserId) {
      try {
        const response = await fetchLookerUrlApi(currentUser.UserId);
        console.log("response", response?.data?.url);
        setEncryptLookerUrl(response?.data?.url);
      } catch (error) {
        console.error("Error fetching Looker URL:", error);
      }
    }
  }, [currentUser?.UserId]);

  useEffect(() => {
    fetchLookerUrl();
  }, [fetchLookerUrl]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex items-center justify-center h-[calc(100vh-127px)] bg-gray-100 mt-[137px]">
      {lookerUrl ? (
        <iframe
          title="Looker"
          width={width}
          height="874"
          src={lookerUrl}
          style={{ border: 0 }}
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Home;
