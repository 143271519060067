import React from "react";
import logo from "../assets/images/logo_70x70.png";

const AuthenticationPage: React.FC = () => {
  const handleLogin = () => {
    window.location.href = `https://accounts.unitedwecare.com/?redirect=${window.location.href}`;
  };

  return (
    <div className="flex items-center justify-center min-h-[90vh] bg-white">
      <div className="p-8 w-full max-w-md text-center">
        <img src={logo} alt="Logo" className="mx-auto mb-4" />
        <h2 className="text-2xl font-bold mb-4">Welcome Back!</h2>
        <p className="mb-6 text-gray-600">
          You must be logged in to access the Clinical Note. Please log in to
          your account to proceed.
        </p>
        <button
          onClick={handleLogin}
          className="bg-primary-500 disabled:bg-primary-400 text-white inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 shadow hover:bg-primary-600 h-9 px-4 py-3.5 w-40"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default AuthenticationPage;
