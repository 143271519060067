import React from "react";
import logo from "../assets/images/logo_70x70.png"; // Update the path accordingly

const NotAuthorized: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-[90vh] bg-white">
      <div className="p-8 w-full max-w-md text-center">
        <img src={logo} alt="Logo" className="mx-auto mb-4" />
        <h2 className="text-2xl font-bold mb-4">Access Denied</h2>
        <p className="mb-6 text-gray-600">
          You are not authorized to access this service. Please use another
          account to proceed.
        </p>
        <button
          onClick={() =>
            (window.location.href = `https://accounts.unitedwecare.com/?redirect=${window.location.href}`)
          }
          className="bg-primary-500 disabled:bg-primary-400 text-white inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 shadow hover:bg-primary-600 h-9 px-4 py-3.5 w-40"
        >
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default NotAuthorized;
