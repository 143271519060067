const secretKey = process.env.REACT_APP_BCRYPT_SECRET_KEY;

if (!secretKey) {
  throw new Error('REACT_APP_BCRYPT_SECRET_KEY environment variable is not set.');
}

export const decryptDataAlt = async (encryptedData: string): Promise<string> => {
  const encryptedDataBytes = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));

  const iv = encryptedDataBytes.slice(0, 12);
  const salt = encryptedDataBytes.slice(12, 28);
  const tag = encryptedDataBytes.slice(28, 44);
  const data = encryptedDataBytes.slice(44);

  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(secretKey),
    'PBKDF2',
    false,
    ['deriveKey']
  );

  const key = await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: salt,
      iterations: 100000,
      hash: 'SHA-256'
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    false,
    ['decrypt']
  );

  const decrypted = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: iv, tagLength: 128 },
    key,
    new Uint8Array([...data, ...tag])
  );

  return new TextDecoder().decode(decrypted);
};
